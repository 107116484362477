<template>
    <div class="drafts">
        <common-table ref="draftsTable" :apiKey="tableConfig.apiKey" :autoLoad="tableConfig.autoLoad"
            :search-params="searchForm" :columns="tableConfig.columns" :options="tableConfig.options" />
    </div>
</template>
<script>
export default {
    name: 'Drafts',
    data() {
        return {
            tableConfig: {
                apiKey: 'fw-wx-mp-getTemplateDraftList',
                autoLoad: true,
                columns: [{
                    label: '创建时间',
                    prop: 'createTime'
                }, {
                    label: '备注',
                    prop: 'userDesc'
                }, {
                    label: '版本号',
                    prop: 'userVersion'
                }],
                options: [{
                    label: '加标准',
                    powerId: '9999-30-10-10',
                    handler: (row) => {
                        this.addToTemplate(row, 1);
                    }
                }, {
                    label: '加普通',
                    powerId: '9999-30-10-10',
                    handler: (row) => {
                        this.addToTemplate(row, 0);
                    }
                }]
            },
            searchForm: {}
        }
    },
    methods: {
        showLoading() {
            return this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        async addToTemplate(row, templateType) {
            let loading = this.showLoading();
            try {
                await this.$http.doApi('fw-wx-mp-addToTemplate', {}, { "draftId": row.draftId, "templateType": templateType });
                this.$message.success('添加成功');
            } catch (e) {
                console.log(e);
                this.$message.error('添加失败');
            } finally {
                loading.close();
            }
        }
    }
}
</script>
<style lang="less"></style>